import React, { FC } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { columns } from '../../constants'

export const Header: FC = React.memo(() => {
  const t = useTranslate()

  return <thead>
    <tr>
      <th key='Comment'>
        <p>{t('Comment')}</p>
      </th>

      {columns.map(column => <th key={column.id}>
        <p>{t(column.title)}</p>
      </th>,
      )}
    </tr>
  </thead>
})
