/* eslint-disable */
import notificationTasks from './notificationTasks/fr';

const fr = {
  // Validation
  "Send code": "Envoyer le code",
  "Wrong code format": "format de code Incorrect",
  "Send new code": "Envoyer le nouveau code",
  "Enter a number or Email": "Entrez un numéro ou un Email",
  "Validation error": "Erreur de validation",
  "Error during validation": "erreur Lors du processus de validation",
  "Error requesting code":"erreur Lors de la demande du code de confirmation",
  "This is a required field":"c'est un champ obligatoire",
  "Password cannot be less than 8 characters": "le mot de Passe doit contenir au moins 8 caractères (majuscules et minuscules, chiffres et autres caractères)",
  "At least 6 characters, only numbers and Latin letters": "au moins 6 caractères, uniquement des chiffres et des lettres latines",
  "Wrong email or phone number":"email ou téléphone saisi de manière Incorrecte",
  "Confirm password mismatch":"les mots de Passe ne correspondent pas",
  "Password restored": "mot de Passe récupéré",
  "Password success changed": "le mot de Passe a été récupéré avec succès. Vous pouvez utiliser votre nouveau mot de passe pour vous connecter",

  // Notification Tasks
  notificationTasks,

  // Common
  "To generate The report, you must specify The email address": "pour générer le rapport, vous devez spécifier l'adresse e-mail",
  "set email": "set email",
  "Repeat password": "Répéter le mot de passe",
  "New password": "Nouveau mot de passe",
  "Confirmation code sent to The email / phone number":"un code de confirmation a été envoyé au téléphone/email spécifié",
  "Reset password": "Récupération du mot de passe",
  "Next": "Suivant",
  "To signin": "To SignIn",
  "Phone / Email": "Téléphone / Email",
  "OK": "ОК",
  "Open": "Sélectionner les fichiers",
  "Import data from USB": "Importer des données depuis USB",
  "Wrong file format":"Format de fichier non pris en charge",
  "import completed": "Données importées",
  "The geozone should not self-crossing": "La géozone ne devrait pas s'auto-traverser",
  more: "Détails",
  "sign_out": "Sortie",
  "helpers": "Info-bulles",
  "desktop": "Écran d'accueil",
  "map (new)": "Carte (nouvelle)",
  "myMachines": "de la machine",
  "tracking": "Surveillance",
  "watch": "Analyse",
  "notifications": "Notifications",
  "Notifications": "Notifications",
  "settings": "Gestion",
  sortByQuantity: "Trier par quantité",
  "Not found": "Non trouvé",
  "map": "Carte",
  "activity": "Activité",
  "removed": "Récolté/traité",
  "name_of_the_farm": "Dénomination de l’exploitation",
  "machines_total": "Machines au total",
  "active": "Actifs",
  "warning": "Critiques",
  amountOfHours: "Le nombre d'heures",
  "farming_name": "Dénomination de l’exploitation",
  "machines_quantity": "Machines au total",
  "activities": "Activité",
  "fuel": "Combustible",
  "conservants": "Système d'application conservateur",
  "machine": "Machine",
  "inclusion_DVS": "Démarrage du MCI",
  "last_data": "Data récentes",
  "speed_at_now": "Vitesse courante",
  "speed": "Vitesse",
  "average_speed_move_in_harvest": "Vitesse moyenne",
  "expense_of_fuel": "Consommation de combustible",
  "area_cleaned": "Surface récoltée/traitée",
  "performance": "Rendement",
  "watching": "Surveillance",
  "status": "Statut",
  "type_of_machine": "Modèle de la machine",
  "farming": "Exploitation",
  "today": "Aujourd'hui",
  day: "Journée",
  month: "Mois",
  year: "Année",
  "week": "Semaine",
  "date": "Date",
  "select_a_date": "Choisir les date",
  "track": "Trace",
  "accept": "Accepter",
  "range_of_date": "Période du temps",
  "drain": "Vidanges",
  "refuel": "Remplissages",
  "indications_of_work": "Indicateurs de fonctionnement",
  "consolidated_statement_about_machines": "Rapport de synthèse concernant les machines",
  "events_a_o": "Evénements selon le rapport sommaire",
  "export": "Export",
  "model": "modèle",
  "time": "Heure",
  "status_of_message": "Statut du message",
  "category": "Catégorie",
  "found": "Trouvé",
  "selecting_date": "Choisir les date",
  "analysis": "Analyse",
  "use_of_time": "Utilisation du temps",
  "report_about_machine": "Rapport relatif à la machine",
  "fuel_report": "Rapport à l’égard du combustible",
  "harvest": "Mode de fonctionnement",
  "driving_mode": "Mode routier",
  "work_engine_in_no_movement": "Immobilisation avec le moteur activé",
  "liters": "litres",
  "machines": "des machines",
  "farmings": "Exploitations",
  "geozones": "Zones géographiques",
  "search": "Recherche",
  "search_for": "Recherche des exploitations et des machines",
  "search_for_geozone": "Recherche de la zone géographique",
  "params_of_report": "Paramètres du rapport",
  "format_of_file": "Format du fichier",
  "type": "Type",
  "events": "Événements",
  "event": "Événement",
  "count": "Quantité",
  "duration": "Durée",
  "date_of_start": "Date du début",
  "time_of_start": "Heure du début",
  "time_of_end": "Heure de la fin",
  cancelChanges: "Modifications annulées",
  saveProfileWait: "Les informations sont enregistrées",
  saveProfileDone: "Informations enregistrées",
  saveProfileError:"une erreur s'Est produite lors de l'enregistrement",
  savePhotoWait: "La photo est en cours d'enregistrement",
  savePhotoDone: "Photo enregistrée",
  deleteProfileWait: "Les informations sont supprimées",
  deleteProfileDone: "Informations supprimées",
  pleaseWait: "Veuillez patienter",
  deletePhoto: "Photo supprimée",
  photoTooLarge: "Photo trop grande",
  maxPhotoSize: "Taille maximale de la photo: 2 Mo",
  falsePhotoFormat: "Format de photo non pris en charge",
  supportedPhotoFormats: "Formats photo pris en charge: JPEG, JPG, PNG",
  "A final report about harvesting": "Rapport final concernant la récolte/fonctionnement",
  "A daily report about harvesting (compact)": "Rapport quotidien concernant la récolte/fonctionnement (compact)",
  "A final report about used time": "Rapport final relatif à l’utilisation du temps",
  "An overall report about operation time": "Rapport sommaires concernant les durées de fonctionnement",
  "Daily Cleaning/Work Report (KYK)": "Daily Cleaning / Work Report (COMMENT)",
  "A final report about harvesting (compact)": "Rapport final concernant la récolte/fonctionnement (compact)",
  "A final report about machine": "Rapport final concernant les machines",
  "A daily report about used time": "Rapport de 24 heures relatif à l’utilisation du temps",
  "A daily report about harvesting": "Rapport de 24 heures concernant la récolte/fonctionnement",
  "A daily report about machine": "Rapport de 24 heures concernant les machines",

  "A report about machine": "Un rapport sur la machine",
  "A final report agricultural operations": "Un rapport final des opérations agricoles",
  "A detail report agricultural operations": "Un rapport détaillé des opérations agricoles",
  "A report of fuel": "Un rapport de carburant",
  "Filling/Drain": "Remplissage / Vidange",

  "export_of_report": "Exportation du rapport",
  "export_of_reports": "Exportation du rapports",
  "Please select unit(s) and time period": "Veuillez sélectionner les unités et la date / période",
  "by_model": "Selon le modèle",
  "by_active": "Selon l’activité",
  "not_of_data": "Pas de données",
  "selecting": "Choisir tout",
  "deselecting": "Enlever tout",
  "Navigation menu by pages": "Menu de navigation à travers les pages",
  "profile settings": "paramètres du profil",
  "farmings list": "Liste des exploitations",
  "Change zoom of map Can use scroll of mouse or multitouch": "Modification de l’échelle de la carte. On peut utiliser la fonction de défilement du souris ou étendre /approcher deux doigts sur la carte",
  "Switch on/ switch off fullscreen mode": "vue plein écran",
  "Show center of a map": "Centrage de la carte",
  "The selection type a map": "Choix du type de la carte",
  "The map show all machines Click on machine, to open information about this machine": "Carte avec l’affichage de toutes les machines disponibles. Pour regarder l’information à l’égard de la machine concrète, il faut cliquer sur l’icône avec le bouton gauche du souris",
  "Cost fuel for regimes all machines": "Consommations de combustible selon les modes de fonctionnements, pour toutes les machines",
  "Percent of time, when machines had active regime": "Temps en pour-cent du fonctionnement de toutes les machines dans les modes différents de fonctionnement",
  "serial n": "Numéro de série",
  "Start of fuel": "Début, réservoir, l.",
  "End of fuel": "Fin, réservoir, l.",
  "filling": "Rempli, réservoir, l.",
  "drain_of_day": "Vidangé, réservoir, l.",
  "summFuel": "Consommation général, l",
  "summFuelMove": "Consommation en mode de transport, l.",
  "summFuelComb": "Consommation lors de la récolte /du fonctionnement, l.",
  "summFuelStand": "Consommation lors de la période de l’immobilisation, l.",
  "fuelHour": "Consommation moyenne, l/h.",
  "engineTime": "Durée de fonctionnement du MCI, h.",
  "engineTimeMove": "Durée dans le mode de transport, h.",
  "engineTimeComb": "Durée de la récolte /du fonctionnement, h.",
  "engineTimeStop": "Durée de la période de l’immobilisation, h.",
  "phone": "Téléphone",
  "clear": "Nettoyer",
  "clear filter": "Nettoyer le filtre",
  "fixed options of filter": "Fixer les paramètres de la filtration",
  "M0": "Connexion absente",
  "M2": "Désactivé",
  "M5": "Période de l’immobilisation avec les agrégats activés ",
  "M6": "Période de l’immobilisation avec les agrégats activés de travail et réservoir plein",
  "M1": "Marche",
  "M3": "Immobilisation avec le moteur activé",
  "M4": "Mode de fonctionnement",
  Idle: "Immobilisation",
  "UPLOAD": "Déchargement",
  "BUNKER_FULL": "Trémie remplie",
  "details": "Détails",
  "FILING": "Remplissage",
  "DRAIN": "Vidange",
  "All": "Tous",
  "All machines": "tous véhicules",
  "harvesting machinery": "Machines récolteuses",
  "sowing machinery": "Semoirs",
  "tractor": "du tracteur",
  "clear_filter": "Nettoyer la filtration",
  "critical": "Critique",
  "not_critical": "Non critique",
  "informational": "Informationnel",
  "service": "Service technique",
  "engine": "Moteur",
  "fuel_system": "Système d’alimentation en combustible",
  "adapter_reaper": "Adaptateur et moissonneuse",
  "reaper": "moissonneuse",
  "inclined_camera_feeder": "Trémie inclinée et le dispositif alimentaire",
  "cabin": "Cabine",
  "thresher": "Batteur pour la moissonneuse-batteuse et le broyeur pour la faucheuse-hacheuse-chargeuse",
  "cleaning": "Nettoyage",
  "chassis": "Train de roulement",
  "hopper": "Trémie",
  "stacker": "Broyeur à paille et moyetteur",
  "hydraulics": "Système hydraulique",
  "electrics": "Equipement électrique et électronique",
  "notification": "Notification",
  "harvesting": "récolte",
  "hopper_full": "Trémie remplie",
  "unloading": "Déchargement",
  "Loading data": "Chargement des données",
  "downtime": "Immobilisations",
  "idle_time_<_1": "immobilisation avec le MCI activé jusqu’à 1 minute",
  "idle_time_>_1": "immobilisation avec le MCI activé plus de 1 minute",
  "stop_<_1": "arrêt de durée inférieure à 1 minute",
  "stop_1_10": "arrêt de durée de 1 à 10 minutes",
  "stop_>_10": "arrêt de durée supérieure à 10 minutes",
  "summary": "Final",
  "detail": "Détaillé",
  "agriculture": "Culture",
  "rating": "Classer selon le rating",
  "ON_UPLOAD": "Déchargement",
  "ON_100": "Trémie remplie",
  "group": "De groupe",
  "single": "Unique",
  "Set a date": "Saisir la date",
  "Indicators at moment": "Indice courants",
  "Technique in testing": "Matériel soumis à l'essai",
  "Maintenance": "Service technique",
  "Aggregates": "Agrégats",
  "Status at now": "Statut actuel",
  "Move speed of combine": "Vitesse de marche de la faucheuse-hacheuse-chargeuse",
  "Level fuel in tank": "Niveau de combustible dans le réservoir",
  "Performance at now": "Rendement courant",
  "is active": "Actif",
  "not active": "Inactif",
  "download excel": "Télécharger (excel)",
  "report cleaning": "Rapport consternant le battage",
  "make graphics": "Construire les graphiques",
  "Contact form": "Contacter",
  "Reasons contact": "Causes de la demande",
  "Service": "Service",
  "Contact phone": "Numéro de téléphone",
  "Message": "Message",
  "Serial number machine": "Numéro de série de la machine",
  "Type technics": "Type des engins",
  "submit": "Envoyer",
  "profile": "Profil",
  "Load photo": "Télécharger le photo",
  "noname": "Nom n’est pas trouvé",
  "noemail": "E-mail n’est pas trouvé",
  "language": "Langue",
  "ru": "Русский",
  "en": "English",
  "fr": "Le français",
  "de": "Deutsch",
  "Serial number search": "Recherche de la machine selon le numéro de série",
  "For the current day": "au cours du jour courant",
  "feedback": "Contacter",
  "period": "period",
  "sprayers": "Arroseur",
  "mode": "Mode",
  "level fuel, l": "Niveau de combustible, l",
  "fuel graphics": "Graphique de carburant",
  "creating geozones": "Création de géozones",
  "area": "surface",
  "Last point": "Dernier point",
  "Long": "Longue",
  "km": "km",
  "Save geozone": "Enregistrer la géozone",
  "Active point": "Point actif",
  "Continue": "Continuer",
  "Delete point": "Supprimer le point",
  "shape": "Téléchargement du fichier shape",
  "Turning on the display of directions on the map": "Activation de l’affichage de la direction sur la carte",
  "Select map tile": "Choix du substrat de la carte",
  "Geozone edit": "Édition de la zone géographique",
  "New geozone": "Nouvelle zone géographique",
  "Name geozone": "Nom zone géographique",
  "Do not display": "Ne pas afficher",
  "Convective precipitation": "Précipitations convectives",
  "Precipitation intensity": "Intensité des précipitations",
  "Accumulated precipitation": "Précipitations accumulées",
  "Accumulated precipitation - rain": "Précipitations accumulées - pluie",
  "Accumulated precipitation - snow": "Précipitations accumulées - neige",
  "Depth of snow": "Profondeur de neige",
  "Wind speed at an altitude of 10 meters": "Vitesse du vent à 10 mètres d'altitude",
  "Joint display of speed wind and wind direction": "Affichage conjoint de la vitesse du vent (couleur) et de la direction du vent (flèches), reçus par les composants U et V",
  "Atmospheric pressure on mean sea level": "Pression atmosphérique au niveau moyen de la mer",
  "Air temperature at a height of 2 meters": "Température de l'air à une hauteur de 2 mètres",
  "Temperature of a dew point": "Température d'un point de rosée",
  "Soil temperature 0-10 сm": "Température du sol 0-10 сm",
  "Soil temperature >10 сm": "Température du sol >10 сm",
  "Relative humidity": "Humidité relative",
  "Cloudiness": "Nébulosité",
  "Start": "Début",
  "Finish": "Fin",
  "sputnic": "Satellite",
  "hybrid": "hybrid",
  "terrain": "terrain",
  "Engine load": "Charge du moteur",
  "Show in list": "Afficher dans la liste",
  "No location data": "Aucunes données concernant l’emplacement",
  "from": "Du",
  "to": "Jusqu’à",
  "good day": "Bon après midi",
  "User profile page": "Page de profil utilisateur",
  "Track in geozone": "Traçage dans la zone géographique",
  "Scaling": "Mise à l'échelle",
  "Select the period of the show track": "Choix de la période de l’affichage de la trace",
  "No data for the this period": "Aucunes données pour la période indiquée",
  "Attention! Request for notifications is limited to": "Attention! La demande de notification est limitée à",
  "days Please change the request parameters": "jours. Veuillez modifier les paramètres de la demande",
  "Track by mode": "Traçage selon les régimes",
  "Track by speed": "Traçage selon la vitesse",
  "Track by load": "Traçage selon la charge",
  "Delete track": "Eliminer la trace",
  "Track control panel (select track type, delete track)": "Panneau de commande des traces (choix du type de la trace, l’élimination de la trace)",
  "Geozone name": "Nom de la zone géographique",
  "Time of entry": "Heure de l’entrée",
  "Departure time": "Heure du départ",
  "Time in geozone": "Temps passé dans la zone géographique",
  "Current status of the vehicle": "Statut courant de la technique",
  "Make and model of technology": "Marque et modèle de technologie",
  "Level fuel": "Niveau de combustible",
  "Track building": "Etablissement de la trace",
  "Track": "Traçabilité",
  "Enable / Disable the display of technology on the map": "Activation/déactivation de l’affichage de la technique sur la carte",
  "Total fuel spent on all available units": "Combustible total dépensé pour tous les unités disponibles",
  "Fuel consumption by mode for all units": "Consommations de combustible selon les modes de fonctionnements, pour toutes les machines",
  "main diagram": "La surface totale récoltée / traitée et le diagramme du temps écoulé pour chaque mode pour toutes les machines.",
  "data for current day": "données pour le jour actuel",
  "Show / hide geofzonee on map": "Montrer/cacher l’affichage de la zone géographique sur la carte",
  "Select map tool": "Sélectionner l'outil de carte",
  "Show / hide geozone detail information": "Montrer/chacher l’information détaillée concernant la zone géographique",
  "Geozone type": "Type de la zone géographique",
  "Delete": "Elimination",
  "Delete geozone": "Elimination de la zone géographique",
  "Edit geozone": "Rédaction de la zone géographique",
  "Enable geozone editing mode": "Mise en ouvre du régime de la rédaction de la zone géographique",
  "save": "Sauvegarder",
  "cancel": "Annuler",
  "The range between The selected dates must not exceed 30 days": "la Plage entre les dates sélectionnées ne doit pas dépasser 30 jours",
  "The interval selected is too large": "l'intervalle sélectionné est Trop grand",
  "Close": "Fermer",
  "Name": "Prénom",
  "Surname": "Nom de famille",
  "middle name": "Deuxième prénom",
  role: "Position",
  "By mode": "Par mode",
  "By days": "Par jours",
  "Analysis of working time by mode": "Analyse du temps de travail par mode",
  "Analysis of working time by day": "Analyse du temps de travail par jour",
  "Graph of the analysis of working time by group / unit of equipment for the selected period": "Graphique de l'analyse du temps de travail par groupe / unité d'équipement pour la période sélectionnée",
  "Graph of the analysis of working time for the group / unit of equipment for the selected period It is possible to turn on / off the display of modes": "Graphique de l'analyse du temps de travail pour le groupe / l'unité d'équipement pour la période sélectionnée. Il est possible d'activer / désactiver l'affichage des modes",
  "Fuel in the tank at the beginning of the day": "Combustible dans le réservoir au début du jour",
  "Fuel in the tank at the end of the day": "Combustible dans le réservoir à la fin du jour",
  "Refills per day": "Remplissages en vingt-quatre heures",
  "Total consumption": "Consommation général",
  "Consumption in transport mode": "Consommation en mode de transport",
  "Consumption in cleaning / work": "Consommation lors de la récolte /du fonctionnement",
  "Idle flow": "Consommation lors de la période de l’immobilisation",
  "Average consumption": "Consommation moyenne",
  "ICE working time": "Durée de fonctionnement du MCI",
  "Time in transport mode": "Durée dans le mode de transport",
  "Time in cleaning / work": "Durée de la récolte /du fonctionnement",
  "Idle time": "Durée de la période de l’immobilisation",
  "Table filling/drain": "Table des vidanges/des remplissages",
  "Volume": "Volume",
  "Other": "Autre",
  "Combine Harvesters": "Moissonneuses-batteuses",
  "Forage Harvesters": "Fourragères",
  "Power facilities": "Installations électriques",
  "Tractors": "Tracteurs",
  "Treated area": "Zone traitée",
  "Distance traveled": "Distance parcourue",
  "Engine running time": "Temps moteur",
  "Fuel consumption": "Consommation de carburant",
  "Average time performance": "Performance temporelle moyenne",
  "Yes": "Oui",
  "No": "Non",
  "Work performance graph": "Graphe des indices du fonctionnement",
  "The choice of parameters for display on the graph (with the ability to change color)": "Choix des paramètres pour visualiser dans le graphe (avec la possibilité de modifier la couleur)",
  "Select graphics": "Sélectionner des graphiques",
  "Types de rapports disponibles pour exportation dans un fichier Export disponible au format Excel": "Types des rapports, disponibles pour les exporter dans le fichier. Il est possible d’exporter dans le format excel",
  "Download": "Télécharger",
  "The number of units in different modes in the household": "Nombre des unités dans les différents régimes dans l’exploitation",
  "Department of service vehicles": "Département des véhicules de service",
  "Spare Parts Sales Department": "Département des ventes de pièces de rechange",
  "Support Agrotronic": "Service de support Agrotronic",
  "Sprinklers": "Arroseur",
  "Mower": "Tondeuse",
  "Appearance vehicles": "Apparence des véhicules",
  "Current technical information: percentage of fuel tank filling, current working status of the equipment, date and time of the last activity of the equipment": "Information courante envers la technique : pourcent du remplissage du réservoir de combustible, le statut courant du fonctionnement de la technique, la date et l’heure de la dernière activité de la technique",
  "Activity status of the tracking function (if selected, the tracking mode for this unit of equipment is turned on and transferred to the map)": "Statut de l’activité de la fonctionne de la surveillance de la technique (au cas du clique avec le bouton droit du souris, on voit activer le régime de surveillance de la machine concrète)",
  "Refresh": "Rafraîchir",
  "Detailed parameters of the equipment": "Paramètres détaillés du fonctionnement des engins",
  "List of user geozones with the search function by name": "Liste des zones géographiques de l’utilisateur avec la fonction de la recherche selon la dénomination",
  "List of households and units of user equipment with the search function by name": "Liste des exploitations et des unités de la technique de l’utilisateur avec la fonction de la recherche selon la dénomination",
  "Toggle viewing of objects on the map": "Commutation du régime de l’affichage des objets sur la carte",
  "Event name": "Dénomination de l’événement",
  "Quantity for the period": "Quantité pour la période",
  "Duration for the period": "Durée pour la période",
  "Event start date": "Date du début de l’événement",
  "Event start time": "Heure du début de l’événement",
  "Event end time": "Heure de l’achèvement de l’événement",
  "Event duration": "Durée de l’événement",
  "Event location": "Emplacement de l’événement",
  "Machine condition for the selected period": "État de la machine pour la période sélectionnée",
  "Report Type Selection Menu": "Menu du choix du type du rapport",
  "Select one or more values": "Choix d’une ou de plusieurs valeurs",
  "Time period selection": "Choix de la période du temps",
  "Apply selected parameters in the filter": "Application des paramètres choisis dans le filtre",
  "Search the machine by serial number": "Recherche de la machine selon le numéro de série",
  "Select one unit of equipment": "Choix d’une unité de la technique",
  "Choice of equipment / group of equipment": "Choix de la technique /du groupe de la technique",
  "Single parameter selection": "Choix du paramètre unique",
  "wrong login or password": "Nom d'utilisateur ou mot de passe invalide",
  "Something went wrong. Error code": "Quelque chose s'est mal passé. Code d'erreur",
  "Login": "S'identifier",
  "Password": "Mot de passe",
  "Entry": "Connexion",
  "Demo mode": "Mode démo",
  "Vechicle info": "Information sur la machine : icone du type de la machine (couleur : grise – « Communication absente », noire – « Désactivé » (fonctionnement sans allumage activé), rouge – « immobilisation avec  MCI activé, bleue – « Marche », verte – « Récolte/Fonctionnement »). Au cas de la pression sur la dénomination du modèle ou du numéro de série – le passage vers la page avec l’information détaillée sur la machine",
  "The time of the first start of the engine for the current day": "Heure du premier démarrage du MCI au cours du jour courant",
  "Date and time of the last communication session": "Date et l’heure de la dernière séance de communication",
  "Pie chart modes of operation for the current day": "Diagramme circulaire des régimes de fonctionnement de la technique au cours du jour courant",
  "Average speed of equipment in the mode of combining / working for the current day": "Vitesse moyenne de la technique en régime du fonctionnement d’une faucheuse-hacheuse-chargeuse / de fonctionnement de la technique au cours du jour courant",
  "Fuel consumption for the current day": "Consommation de combustible de la technique au cours du jour courant",
  "Cleaned / treated area for the current day": "Surface récoltée/traitée au cours du jour courant",
  "Productivity of technology for the current day": "Rendement de la technique au cours du jour courant",
  "Tracking Button": "Bouton de passage vers la surveillance",
  "Notification text": "Texte de la notification",
  "Notification category": "Catégorie de la notification",
  "Date and time finish of notification": "Date et l’heure de l’effacement de la notification",
  "Duration of notification": "Durée de la notification",
  "Notification location": "Emplacement de la notification",
  "List of farm equipment (indicating the number of monitored equipment)": "Liste de la technique de l’exploitation (en indiquant le nombre de la technique sous la surveillance)",
  "dont machine": "Machine introuvable ou non possédé par l'utilisateur",
  "Timezone": "Fuseau horaire",
  "Phone": "Téléphone",
  "Phone number must be at least 11 characters": "Le numéro de téléphone doit comporter au moins 11 caractères.",
  "Are you sure you want to delete the geozone": "Êtes-vous sûr de vouloir supprimer la barrière géographique?",
  "Currently edit imported track do not support": "Actuellement, la piste importée ne prend pas en charge",
  "Productivity map": "Carte de productivité",
  "Productivity map wet KUK": "Carte de productivité KUK par poids humide",
  "Productivity map dry KUK": "Carte de productivité KUK par poids sec",
  "Productivity": "Productivité",
  "Create map": "Créer une carte",
  "Download map": "Télécharger la carte",
  "Part width": "Partie largeur",
  "Choose vehicles": "Choisissez des véhicules",
  "Create": "Créer",
  "Planning": "Routeur RSM",
  "Agrotronic": "Agrotronic",
  "Alerts": "Les alertes",
  "Fields": "Champs",
  "Production operations": "Opérations de production",
  "Technological maps": "Cartes technologiques",
  "Alert tasks": "Tâches d'alerte",
  "Inbox": "Boîte de réception",
  "Catalogs": "Des catalogues",
  "Users": "Utilisateurs",
  "Farms": "Entreprises",
  "AdminPanel": "Adm. panneau",
  "Organizations types": "Types d'organisations",
  "Roles": "Les rôles",

  "Deactivated": "Désactivé",
  "Activated in the automatic NC hold mode": "Retention NC",
  "Activated in the mode of automatic maintenance of the cut height": "Wahrung der Schnitthöhe",
  "Activated in the automatic pressure maintenance mode": "Druckhaltung",
  "403 FORBIDDEN \"Too many machines selected\"": "Too many cars selected",

  "km/h": "km/h",
  "l": "l",
  "ha/h": "Ha/h",
  "ha": "Ha",
  "ms": "ms",
  "m": "м",
  "min": "min",
  "t/ha": "t/ha",
  "h": "h",
  "cwt/ha": "cwt/ha",
  // MAP
  "fullscreen": "Ouvrir la carte en plein écran",
  "exit fullscreen": "Quitter le mode plein écran",
  "zoom in": "Agrandir",
  'zoom out': "Dézoomer",
  "Search the machine": "Rechercher la machine",
  contact_information: "Informations de contact",
  iAcceptTerms: "En continuant à utiliser APK Agrotronic, vous acceptez les termes et conditions",
  termsOfUse: "conditions d'utilisation",
  deleteInfo: "Supprimer les données",
  deleteInfoQuestion: "Voulez-vous vraiment supprimer toutes les données?",
  "Filter": "Filtre",
  "Apply filter": "Appliquer le filtre",
  "of": "de",
  "Rows per page": "Lignes par page",
  "Incorrect time period": "Période incorrecte sélectionnée",
  maxVehicleCountRequest: "Nombre maximum de machines pour l'analyse:",
  "The weather info currently unvailable": "Les informations météo actuellement indisponibles",
  "Create geozone for receive weather info": "Créer une zone géographique pour recevoir des informations météorologiques",
  "download app": "Téléchargez l'application",
  "square": "Zone",
  "Total amount of cultivated area": "Quantité totale de surface cultivée",
  "All units": "Équipement total",
  "unit": "machines",
  "unit_plural": "machines",
  "Maintance": "Maintenance",
  "weather": "Temps",
  "Widgets": "Bibliothèque de widgets",
  "antialiasing": "Anti crénelage",
  "Move forward": "Avancer",
  "Neutral gear": "Neutre",
  "Back": "Retour retour",
  "Parking": "Parking",
  predictUnloading: 'Déchargement estimé',
  "Download app for any device": "Téléchargez l'application pour n'importe quel appareil",
  "license plate": "Plaques",
  brand: "Marque",
  allowed: "Permis",
  forbidden: "Interdit",
  transport: "Véhicule",
  "Error load file: need type file zip": "Erreur de chargement du fichier: nécessite un fichier de type .zip",
  "Error load file: need type file txt": "Erreur de chargement du fichier: nécessite un fichier de type .txt",
  "Error load file": "Erreur de chargement du fichier.",
  "Need file types": "Types de fichiers valides:",
  "File downloaded": "Fichier téléchargé",
  "File read error": "Erreur de lecture de fichier",
  "Coordinates are missing in the file": "Les coordonnées sont manquantes dans le fichier",
  "Shape file is not correct": "Le fichier de forme n'est pas correct",
  "Shape file have not supported type": "Shape le fichier de cette structure n'est pas pris en charge. Seules les structures de type Polygon, MultiLineString, LineString, MultiPoint sont prises en charge dans cette version",
  "num of unloading": "nombre de déchargements",
  "osht": "OSHT",
  "download apk": "Télécharger l'apk",
  "no data": "Aucune donnée pour la période sélectionnée",
  "report equipment": "Rapports de temps de fonctionnement de l'équipement",
  "report performance": "Performance",
  "operating time": "Temps de fonctionnement",
  "eh": "Les heures",
  "aggregate": "Unité",
  "aggregates": "Agrégats",
  "total": "Total",
  "Unknown transport": "Piste inconnue",
  "value must be": "La valeur doit être",
  "greater than previous one": "supérieure à la précédente",
  "less than following": "inférieure à la suivante",
  "value cannot be less than 0": "La valeur ne peut pas être inférieure à 0",
  "Date and time": "Date et l'heure",
  "Report": "Rapport",
  "during the period": "pendant la période",
  "Parameters": "Paramètres",
  "Parameter": "Paramètre",
  "Meaning": "Valeur",
  "Error creating file": "Erreur lors de la création du fichier",

  // Культуры
  "bean": "haricot",
  "mustard": "moutarde",
  "pea": ",pois",
  "buckwheat": "sarrasin",
  "dactylis glomerata": "dactylis glomerata",
  "clover": "trèfle",
  "castor-oil plant": ",ricin",
  "hemp": "chanvre",
  "maize corn": "maïs maïs",
  "sesame": "sésame",
  "lucerne": ",luzerne",
  "flax": "lin",
  "papaver": "papaver",
  "chick-pea": "pois chiche",
  "oat": "avoine",
  "festuca pratensis": "festuca pratensis",
  "panicum": "panicum",
  "sunflower": "tournesol",
  "wheat": "blé",
  "wheat winter": "blé d'hiver",
  "wheat spring": "blé de printemps",
  "arrhenatherum": "arrhenatherum",
  "rape": " colza",
  "rice": "riz",
  "rye": "seigle",
  "safflower": "carthame",
  "sugar-beet": "betterave",
  "sorghum": "sorgho",
  "triticale": "triticale",
  "soy": "soja",
  "haricot": "haricot",
  "cotton": "coton",
  "lentil": "lentille",
  "barley": "orge",
  "barley winter": " orge hiver",
  "barley spring": "orge printemps",
  "user1": "user1",
  "user2": "user2",
  "user3": "user3",
  "user4": "user4",
  "corn long wet": "maïs long humide",
  "corn long": "maïs long",
  "corn long dry": "maïs long sec",
  "corn short wet": "maïs court humide",
  "corn short": "maïs court",
  "corn short dry": "maïs court sec",
  "silage": "ensilage",
  "grass": "herbe",
  "grass dry": "herbe sèche",
  "grass normal": "herbe normale",
  "grass wet": "herbe humide",
  "User manual Agrotronic Pilot": "Manuel d'utilisation Agrotronic Pilot",
  "User manual Agrotronic": "Manuel d'utilisation Agrotronic",
  "Agrotronic Administrator Tools Description": "Description des outils d'administration de l'APK Agrotronic",
  "User manual cruise control KUK": "user manual Cruise Control KUK",
  "Humidity sensor FH": "User's Guide Yield Mapping for chopper",
  "n/d": "il n'y a pas de données",
  "about field": "sur le terrain",
  "about operator": "à propos de l'opérateur",
  "about technique": "sur la technique",
  "about task": "sur la tâche",
  "about operation": "sur l'opération",
  "operation": "Opération",
  "task": "Exercer",
  "reporting service is not available": "Le service de rapport n'est pas disponible",
  "Select data source": "Lorsque cette fonction est activée, les cartes du rendement et de l'humidité sur les sites où il n'y a pas de données du PCM Agrotronic du pilote (Tablette) sont construites à partir des données du système de bord standard",
  "Select type of map": "Sélectionnez le type de carte",
  "service is not available": "Le service n'est pas disponible",
  "track service is not available": "Le service de construction de voies n'est pas disponible",
  "all sht": "Toutes les machines agricoles",
  "operators": "Les opérateurs",
  "all fields": "Tous les champs",
  "all operators": "Tous les opérateurs",
  "find operator": "Trouver un opérateur",
  "find field": "Champ de recherche",
  "find technique": "trouver une technique",
  "formed": "Formé",
  "performance report for the period": "Rapport de performance pour la période",
  "the report is generated with filters": "Le rapport est généré avec des filtres",
  "twenty-four hours": "journée",
  "hours": "horloge",
  "minutes": "minutes",
  "incl": "incl",
  "total according to the report": "total selon le rapport",
  "driver report": "Rapport de conducteur",
  "title": "Titre",
  "date added": "Date ajoutée",
  "change history": "Historique des modifications",
  "changed to": "Changé en",
  "changed": "Modifié",
  "date of change": "Date de changement",
  "driver": "chauffeur",
  "biometrics changed": "la biométrie a changé",
  "information": "Information",
  "driver information": "Informations sur le conducteur",
  "the report was generated without filters": "Le rapport a été généré sans filtres",
  "holding": "en portant",
  "Threshing sensor used": "Le capteur de battage est utilisé",
  "n period": "Période n°",
  "startHarvesting": "Début de la récolte",
  "Fin de récolte": "Fin de récolte",
  "Période de récolte": "Période de récolte",
  "harvested agriculture": "Cultures récoltées",
  "density": "Densité, kg / mètre cube",
  "Harvesting time for period": "Temps de nettoyage pour la période, h",
  "Harvested crop for period": "La récolte récoltée pour la période, t",
  "Harvesting time": "temps de récolte",
  "Harvested crop": "récolte Récoltée",
  "Utilisateurs": "Utilisateurs",
  "Fermes": "Fermes",
  "report unloading": "Rapport de déchargement",
  "You can request The code again via":"vous pouvez Demander le code à nouveau via",
  "Confirm": "Confirmer",
  "Send SMS code": "Envoyer le code SMS",
  "SMS code": "code SMS",
  "Confirmation": "Confirmation",
  "Change phone": "Changer de téléphone",
  "To change the phone number, you must enter a confirmation code": "pour changer le numéro de téléphone, vous devez entrer le code de confirmation, il est envoyé dans un message SMS au nouveau numéro de téléphone",
  "Two-factor authentication": "authentification à deux Facteurs",
  "To confirm The action": "pour confirmer l'action, vous devez entrer le code de confirmation, il est envoyé dans un message SMS à votre téléphone.",
  "To confirm The changes": "pour confirmer les modifications, vous devez entrer le code de confirmation, il sera envoyé dans un message SMS à votre téléphone.",
  "Error confirming code": "erreur de confirmation",
  "Error occurred during validation process": "une erreur s'Est produite pendant le processus de validation",
  "Wrong code": "code Incorrect",
  "Phone number successfully changed":"le Numéro de téléphone a été modifié avec succès",
  "Modifications enregistrées avec succès": "Modifications enregistrées avec succès",
  "Display machine data": "Afficher les données des machines",
  "Map Legend Management": "Gestion de la légende de la carte",
  "Error loading calendar coloring pages": "Erreur de chargement des pages à colorier du calendrier",
  "Error while receiving data": "Fehler beim Empfangen der Daten",
  "Invalid address format": "Le format de l'adresse e-mail est incorrect",
  "The phone number is specified in the wrong format": "Le numéro de téléphone est incorrect",
  "Serial number": "numéro de Série",
  "Cocked": "Armé",
  "Reset": "Réinitialisé",
  "Duration, hh:mm:ss": "Durée, HH:mm:SS",
  "hh:mm:ss": "hh:mm:ss",
  "Select a different report or change The filtering conditions": "Sélectionnez un autre rapport ou modifiez les conditions de filtrage",
  "There is no data on which to build this report": "il n'y a Pas de données sur lesquelles générer ce rapport",
  "Username is already in use, use a different one": "Username is already in use, use a different one",
  "Login can contain only numbers and Latin letters": "Login can contain only Numbers and Latin letters",
  "Login cannot consist only of numbers": "la Connexion ne peut pas être composée uniquement de chiffres",
  "Login cannot be less than 6 characters": "Login ne peut pas être inférieur à 6 caractères",
  "The password must contain at least 8 characters": "Au moins 8 caractères, nécessairement contenir des lettres latines majuscules et minuscules, un chiffre et un caractère spécial",
  "Password can only contain numbers, latin letters and special characters": "le mot de Passe ne peut contenir que des chiffres, des lettres latines et des caractères spéciaux",
  "Confirmation code": "Code de confirmation",
  "Approve": "Confirmer",
  "Enter The code sent to The number": "Entrez le code envoyé au numéro",
  "Enter The code sent to The address": "Entrez le code envoyé à l'adresse",
  "An SMS with a confirmation code will be sent to The phone": "un SMS sera Envoyé au téléphone {number} avec le code de confirmation",
  "To The address an email with a confirmation code will be sent": "un e-mail avec un code de confirmation sera Envoyé à {email}",
  "Only mobile numbers of The Russian Federation with The code + 7": "seuls les numéros mobiles de la Fédération de Russie avec le code + 7",
  "Phone Confirmation": "Confirmation Du Téléphone",
  "Email Confirmation": "Email De Confirmation",
  "Enter code": "Entrez le code",
  "On":"On",
  "Event": "Événement",
  "Web": "Web",
  "SMS": "CMC",
  "Action Confirmation": "Confirmation de l'action",
  "To The address": "À l'adresse",
  "confirmation code will be sent": "un e-mail avec le code de confirmation sera envoyé",
  "To The phone": "Sur le téléphone",
  "SMS with a confirmation code will be sent": "SMS with a confirmation code will be sent",

  ////account block module warning
  "Logging in using enterprise accounts will BE blocked in The near future": "⚠️ la Connexion à l'aide de comptes d'entreprise sera bientôt bloquée",
  "Contact The administrator of The company or department to obtain a personal account": "Contactez l'administrateur de l'entreprise ou du département pour obtenir un compte personnel",
  "The changes are aimed at improving data security and expanding usercapabilities": "les Modifications visent à améliorer la sécurité des données et à responsabiliser les utilisateurs",
  "Continue working": "Continuer",
  "You have changed The display of machine data": "Vous avez modifié l'affichage des données de la machine. Mettez à jour les informations pour que les données de rendement s'affichent correctement.",
  "Select at least one machine to build a field field report":"sélectionnez au Moins une machine pour générer un rapport de rendement de champ",
  "Mapped": "Cartographié",
  "Avg yield": "rendement Moyen",
  "Total yield": "récolte Récoltée",
  "The area of The geofence without taking into account The relief of The earth":"Zone de la géofence sans tenir compte du relief de la terre",
  "The area of The geofence for which there is data on yield": "Zone de la Geofence pour laquelle il existe des données sur le rendement",
  "Average yield of The mapped port of The geofence": "rendement Moyen de la partie cartographiée de la Geofence",
  "Indicative (estimated) data obtained on The yield of The field": "Indicative (estimated) data obtained on The yield of The field",
  "Download The field yield report": "Download The field yield report",
  "T": "t",
  "Field Yield Report": "Rapport de rendement du champ",
  "Equipment (Equipment that was in The defence during The specified period)": "Équipement (Équipement qui était dans la zone géographique pendant la période spécifiée)",
  "Map type": "type de carte",
  "Productivity map ZUK": "Carte des rendements ZUK",
  "Moisture map ZUK": "Carte d'humidité ZUK",
  "Moisture map KUK": "Carte d'humidité KUK",
  "Elimination": "Élimination",
  "Appearance": "Apparence",
  "Tire pressure": "Pression des pneus",
  "Auto-guidance system": "Système de guidage automatique",
  "View location is not available, The machine has not yet set The coordinates": "L'affichage de l'emplacement n'est pas disponible, la machine n'a pas encore envoyé les coordonnées",
  "pcs": "PCs",
  "RSM User Manual Auto-unloading KUK": "Manuel d'utilisation RSM Déchargement automatique KUK",
  "Unknown agriculture": "Agriculture inconnue",
  'Time zone': 'fuseau Horaire',
  Greenwich: 'Greenwich',
  Europe: 'Europe',
  Kaliningrad: 'Kaliningrad',
  Moscow: 'Moscou',
  Samara: 'Samara',
  Yekaterinburg: 'Ekaterinburg',
  Omsk: 'Omsk',
  Krasnoyarsk: 'Krasnoyarsk',
  Irkutsk: 'Irkutsk',
  Yakutia: 'Yakutia',
  Vladivostok: 'Vladivostok',
  Magadan: 'Magadan',
  Kamtchatka: 'Kamtchatka',
  'Cape Verde': 'Cap-Vert',
  'Saint Pierre': 'Saint Pierre',
  Argentina: 'Argentine',
  'New York': 'New York',
  Columbia: 'Columbia',
  Guatemala: 'Guatemala',
  Arizona: 'Arizona',
  Alaska: 'Alaska',
  'Gambier Island': 'Île Gambier',
  'Cook Islands':'Îles Cook',
  Niue: 'Niue',
  Biker: 'Motard',
  'Use system time': 'Utiliser heure système',
  'The time zone will be automatically synchronized':'le fuseau Horaire dans AGROTRONIC sera automatiquement synchronisé avec heure sur votre appareil',
  "Azimuth": "Azimuth",
  "Reaper width":"Largeur de la moissonneuse",
  "Overlap width":"largeur du chevauchement",
  "Start coordinates": "Coordonnées de départ",
  "Date, time end": "date, end Time",
  "Date, time start": "date, heure de début",
  "Machine name": "nom de la machine",
  "Comment": "Comment",
  "The machine that created the group": "la Machine qui a créé le groupe",
  "Machine that has joined the group": "Machine connectée au groupe",
  "Group Cleaning Report": "Rapport de nettoyage de groupe"
};
export default fr;
