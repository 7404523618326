import { GroupItem } from '../'

const timeFormatter = (date: Date) => new Date(date).user.format('DD.MM.YY HH:mm:ss')
const toFixedOrNull = (digits: number, value?: number) => value ? value.toFixed(digits) : null

export const prepareData = (columnId: typeof columns[number]['id'], item: GroupItem, formatter: Function) => {
  if(columnId === 'latitude') {
    return [item['latitude'], item['longitude']]
      .filter(Boolean)
      .map((value: number) => value.toFixed(8))
      .join(', ')
  }

  return formatter && item[columnId] ? formatter(item[columnId]) : item[columnId]
}

export const columns = [
  {
    id   : 'name',
    title: 'Machine name',
  },
  {
    formatter: timeFormatter,
    id       : 'start',
    title    : 'Date, time start',
    
  },
  {
    formatter: timeFormatter,
    id       : 'end',
    title    : 'Date, time end',
  },
  {
    id   : 'latitude',
    title: 'Start coordinates',
  },
  {
    formatter: (value: number) => toFixedOrNull(3, value),
    id       : 'azimuth',
    title    : 'Azimuth',
  },
  {
    id   : 'width',
    title: 'Reaper width',
  },
  {
    formatter: (value: number) => toFixedOrNull(1, value),
    id       : 'overlap',
    title    : 'Overlap width',
  },
] as const
