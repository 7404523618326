import { formats } from '@agdt/agrotronic-react-components'
import ExcelJS from 'exceljs'
import { isEmpty } from 'ramda'
import { TLang, TTranslate } from 'types'
import { columns, prepareData } from '../constants'
import { ReportProps } from '../index'

export type TReportProps = {
  from: string | null
  lang: TLang
  report: ReportProps
  t: TTranslate
  to: string | null
  userLogin: string
}

export const createExcelFile = async ({
  from,
  lang,
  report,
  t,
  to,
  userLogin,
}: TReportProps): Promise<void> => {
  const createDateTime = new Date()
  const workbook = new ExcelJS.Workbook()

  try {
    const response = await fetch(`${process.env.PUBLIC_URL}/xlstemplates/commonReport.xlsx`)

    //@ts-expect-error
    await workbook.xlsx.load(response.blob())
  } catch(err) {
    console.error(err)
  }

  const sheet = workbook.worksheets[0]

  //create header
  // eslint-disable-next-line max-len
  sheet.getCell('B1').value = `${t('formed')}: ${ userLogin }, ${formats.date.dateViewFormatter(createDateTime, lang)}, ${formats.date.timeFormatter(createDateTime, lang)}`

  if(from && to) {
    // eslint-disable-next-line max-len
    sheet.getCell('A2').value = `${t('Group Cleaning Report')} ${t('during the period')} ${new Date().user.new(formats.api.parseDateTimeFromApi(from)).format('DD.MM.YY HH:mm:ss')} - ${new Date().user.new(formats.api.parseDateTimeFromApi(to)).format('DD.MM.YY HH:mm:ss')}`
  }

  // Generate column titles
  sheet.insertRow(3, [t('Comment'), ...columns.map((C: any) => t(C.title))])

  //resize title row for full report length
  sheet.mergeCells(1, 2, 1, columns.length + 1)

  //unmerge merged cells
  sheet.unMergeCells(2, 1, 2, 2)

  //resize title row for full report length
  sheet.mergeCells(2, 1, 2, columns.length + 1)

  // Generate report
  let cursor = 4

  report[0].forEach(group => {
    sheet.insertRow(cursor, [
      t('The machine that created the group'),
      ...columns.map(({ id, formatter }) => prepareData(id, group.master, formatter)),
    ])

    ++cursor

    if(!isEmpty(group.slaves)) {
      group.slaves.forEach(slaveMachine => {
        sheet.insertRow(cursor, [
          t('Machine that has joined the group'),
          ...columns.map(({ id, formatter }) => prepareData(id, slaveMachine, formatter)),
        ])

        ++cursor
      })
    }

    sheet.getRow(cursor).alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
  })

  // remove unused row
  sheet.spliceRows(cursor, 1)

  //resize footer for full report length
  sheet.mergeCells(cursor, 1, cursor, columns.length + 1)

  // Force download report
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob(
    [buffer],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  )

  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = 'group_cleaning_report.xlsx'
  anchor.click()
  window.URL.revokeObjectURL(url)
}
