import { isEmpty } from 'ramda'
import React, { FC, useEffect, useRef } from 'react'
import { useTranslate } from 'react-redux-multilingual'
import { ReportProps } from '../'
import { columns, prepareData } from '../constants'
import { Header } from './Header'

export const FilterResult: FC<ReportProps> = ({ report }) => {
  const originalTable = useRef<HTMLTableElement>(null)
  const fixTable = useRef<HTMLTableElement>(null)
  const t = useTranslate()

  const handleScroll = () => {
    if(!fixTable.current || !originalTable.current) {
      return
    }

    if(fixTable.current.style) {
      fixTable.current.style.width = `${originalTable.current.clientWidth}px`
    }

    if(originalTable.current.tHead && fixTable.current.tHead) {
      const elemsOrigTable = Array.from(originalTable.current.tHead.rows[0].cells)
      const elemsFixTable = Array.from(fixTable.current.tHead.rows[0].cells)

      elemsOrigTable.forEach((elem, index) => {
        elemsFixTable[index].style.width = `${elem.clientWidth}px`
      })
    }

    const { top } = originalTable.current.getBoundingClientRect()
    const height = originalTable.current.offsetHeight

    if(top < 0 && -top < height - 80) {
      fixTable.current.style.display = 'block'
    }
    else {
      fixTable.current.style.display = 'none'
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div className="watch__full">
      <table ref={originalTable}>
        <Header />

        <tbody>
          {report[0].map((group, idx) => <>
            <tr key={`${group.groupID}_master_${idx}`}>
              <p>{t('The machine that created the group')}</p>

              {columns.map(({ id, formatter }, index) =>
                <td key={`${id}_${idx}_${index}`}>
                  <p>{prepareData(id, group.master, formatter)}</p>
                </td>)}
            </tr>

            {!isEmpty(group.slaves) && group.slaves.map((slaveMachine, slaveIdx) =>
              <tr key={`${group.groupID}_slave_${slaveIdx}`}>
                <p>{t('Machine that has joined the group')}</p>

                {columns.map(({ id, formatter }) =>
                  <td key={`${group.groupID}_slave_${slaveIdx}_${id}`}>
                    <p>{prepareData(id, slaveMachine, formatter)}</p>
                  </td>)}
              </tr>)}
          </>)}
        </tbody>
      </table>

      <table className={'fixed-table'} ref={fixTable}>
        <Header />
      </table>
    </div>
  )
}
