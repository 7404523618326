import { Spinner } from '@agdt/agrotronic-react-components'
import { Button } from 'antd'
import { isEmpty, pathOr } from 'ramda'
import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import {
  clearReport,
  errorReport,
  finishLoadReport,
  pushGroupCleaningReport,
  pushReport,
  stopLoadReport,
} from 'reducers/reports/actions'
import { ReportType } from 'reducers/reports/state'
import { AppStateType } from 'reducers/store'
import styled from 'styled-components'
import { TUnit } from 'types'
import { LANG_RU } from '../../../constants/lang'
import FilterControl, { FilterControlConfig } from '../../../ui/Filter/filterControl'
import InitFilter from '../../../ui/Filter/initFilter'
import WithNoItemsForReportPlaceholder from '../common/WithNoItemsForReportPlaceholder'
import { FilterResult as ResultTable } from './ResultTable'
import { createExcelFile } from './ToExcell'

export type GroupItem = {
  'azimuth': number | null
  'end': string | null
  'event': number
  'imei': number
  'latitude': number
  'longitude': number
  'name': string
  'overlap': number
  'start': string
  'vehicleID': number
  'width': number
}

type ReportElement = {
  groupID: string
  master: GroupItem
  slaves: GroupItem[]
}

export type ReportProps = {
  report: ReportElement[][]
}

const GroupCleaningReport: FC<FilterControlConfig> = props => {
  const userLogin = useSelector(({ user: { info } }: AppStateType) => info.operatorLogin || info.login || '')
  const lang = useSelector((state: AppStateType) => state.user.info.lang) || LANG_RU
  const t = useTranslate()
  const report = useSelector((state: AppStateType) => state.reports.get('groupCleaningReport'))
  const { filter } = props

  //@ts-expect-error
  const detailLoad = report.get('detailLoad')

  //@ts-expect-error
  const resultLoad = report.get('resultLoad')

  const endLoadReport = detailLoad.size !== resultLoad.size

  //@ts-expect-error
  const onStopReport = report.get('onStopReport')

  //@ts-expect-error
  const groupCleaningReport = report.get('groupCleaningReport')?.toJS()

  //@ts-expect-error
  const error = report.get('error')

  const stateDispatch = useDispatch()
  const translate = useTranslate()

  const onFinishLoadReport = (type: ReportType) => {
    stateDispatch(finishLoadReport(type))
  }

  const exportToExcel = useCallback(() => {
    createExcelFile({
      from  : pathOr('', ['1', 'result', '0', 'from'], filter),
      lang,
      report: groupCleaningReport,
      t,
      to    : pathOr('', ['1', 'result', '0', 'to'], filter),
      userLogin,
    })
  }, [groupCleaningReport, t, userLogin, lang])

  const filterIsEmpty = isEmpty(props.filter.find(I => I.type === 'checkbox-machine')?.items)

  useEffect(() => {
    if(resultLoad.size >= detailLoad.size){
      onFinishLoadReport(ReportType.GroupCleaningReport)
    }
  }, [resultLoad.size, detailLoad.size, onFinishLoadReport])

  return (
    <div className="watch__wrap">
      <WithNoItemsForReportPlaceholder
        filterIsEmpty={filterIsEmpty}
        text={translate('Select a different report or change the filtering conditions')}
        title={translate('There is no data on which to build this report')}
      >
        <FilterControl
          button={true}
          translate={translate}
          disableButton={endLoadReport}
          {...props}
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!onStopReport && endLoadReport &&
            <Spinner />
          }

          {!onStopReport && groupCleaningReport?.[0] && !isEmpty(groupCleaningReport?.[0]) &&
              <Button
                type="primary"
                icon="download"
                style={{
                  marginBottom: '10px',
                  background  : '#78C800',
                  border      : '1px solid #78C800',
                }}
                onClick={exportToExcel}
              >
                {translate('download excel')}
              </Button>
          }
        </div>
      </WithNoItemsForReportPlaceholder>

      {error &&
        <p style={{ color: 'red', textAlign: 'center' }}>
          {error}
        </p>
      }

      {!onStopReport && groupCleaningReport?.[0] && !isEmpty(groupCleaningReport?.[0]) &&
        <ResultTable report={groupCleaningReport} />
      }

      {!onStopReport && isEmpty(groupCleaningReport?.[0]) && <NoDataWrapper>{t('no data')}</NoDataWrapper>}
    </div>
  )
}

export default InitFilter({
  default: [
    {
      type    : 'checkbox-machine',
      name    : 'machine',
      section : 'machine',
      id      : 0,
      result  : [],
      items   : [],
      validate: true,
    },
    {
      id      : 1,
      type    : 'date',
      name    : 'select_a_date',
      section : 'time',
      result  : [],
      validate: true,
    },
  ],

  callback: data => dispatch => {
    data.callbackOk = (result: Record<string, TUnit[]>) => {
      dispatch(pushReport({ data: result, type: ReportType.GroupCleaningReport, resultLoad: [] }))
    }

    data.callbackError = () => {
      dispatch(stopLoadReport(ReportType.GroupCleaningReport))
      dispatch(errorReport(ReportType.GroupCleaningReport))
    }

    dispatch(clearReport(ReportType.GroupCleaningReport))
    pushGroupCleaningReport(dispatch, data, ReportType.GroupCleaningReport)
  },

  clear: () => dispatch => dispatch(clearReport(ReportType.GroupCleaningReport)),

  //@ts-expect-error
})(GroupCleaningReport)

const NoDataWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #888886;
`
