import { apiServiceBindModule } from './index'

// Account
const accountsCall = apiServiceBindModule('accounts')
export const getInfo = accountsCall('getCurrent')
export const editInfo = accountsCall('updateCurrent')
export const getRestrictions = accountsCall('getAccountRestrictions')
export const getAccountsTree = accountsCall('getAll')
export const getAllAccountObjectsById = accountsCall('getAllObjectsById')

// Users
const usersCall = apiServiceBindModule('users')
export const getCurrentUser = usersCall('getCurrentUser')
export const getOperatorUsersAccounts = usersCall('getOperatorUsersAccounts')
export const setOperatorDefaultAccount = usersCall('setOperatorDefaultAccount')
export const updateCurrentUser = usersCall('updateCurrentUser')
export const updateCurrentUserSecured = usersCall('updateCurrentUserSecured')
export const updateCurrentUserContacts = usersCall('updateCurrentUserContacts')
export const validateUserPersonalData = usersCall('validatePersonalData')

// Notifications
const notificationsCall = apiServiceBindModule('notificationTasks')
export const getAllNotificationTasks = notificationsCall('getAll')
export const editNotificationTask = notificationsCall('updateById')

// notificationSettings
const notificationSettingsCall = apiServiceBindModule('notificationSettings')
export const getAllNotificationSettings = notificationSettingsCall('getAll')
export const updateNotificationSettingsByTypeId = notificationSettingsCall('updateByTypeId')

//Objects
const objectsCall = apiServiceBindModule('objects')
export const getObjectById = objectsCall('getById')
export const createObject = objectsCall('create')
export const updateObjectById = objectsCall('updateById')
export const deleteObjectById = objectsCall('deleteById')

//Employees
const employeeCall = apiServiceBindModule('employees')
export const getEmployeeByCode = employeeCall('getByCode')
export const getAllEmployee = employeeCall('getAll')

//Aggregates
const aggregatesCall: <P = unknown, R = unknown>(methodName: string) => (params: P) => Promise<R | null> =
  apiServiceBindModule('aggregates')

export const getAggregatesByCode = aggregatesCall('getByCode')
export const getAllAggregates: <P = unknown, R = unknown>(params?: P) => Promise<R | null> =
  aggregatesCall('getAll')

//Aggregate models
const aggregateModelsCall: <P = unknown, R = unknown>(methodName: string) => (params: P) => Promise<R | null> =
  apiServiceBindModule('aggregatemodels')

export const getAggregateModelById: <P = unknown, R = unknown>(params: P) => Promise<R | null> =
  aggregateModelsCall('getById')

//Transport
const transportCall = apiServiceBindModule('transport')
export const getTransportByCode = transportCall('getByCode')

//Units
const unitsCall = apiServiceBindModule('units')
export const getAllSensors = unitsCall('getAllSensors')
export const getAllUnits = unitsCall('getAll')

//PrecisionMap SHP
const precisionMap = apiServiceBindModule('precisionMap')
export const getSHP = precisionMap('getSHPFile')
export const getBuild = precisionMap('getBuild')

//operations
const operations = apiServiceBindModule('operations')
export const updateTaskStatuses = operations('updateTaskStatuses')

//reports
const reports: <P = unknown, R = unknown>(methodName: string) => (params: P) => Promise<R | null> =
  apiServiceBindModule('reports')

export const getAgroOperations = reports('getAgroOperations')
export const getCarrierUnloads = reports('getCarrierUnloads')
export const getFarmUnloads = reports('getFarmUnloads')
export const getFill = reports('getFill')
export const getFuel = reports('getFuel')
export const getGroupCleaning = reports('getGroupCleaning')
export const getHarvesting: <P = unknown, R = unknown>(params: P) => Promise<R | null> = reports('getHarvesting')
export const getOperators: <P = unknown, R = unknown>(params?: P) => Promise<R | null> = reports('getOperators')
export const getPerformanceReport = reports('getPerformance')
export const getReportToEmail = reports('getReportToEmail')
export const getVehicleEquipment: <P = unknown, R = unknown>(params: P) => Promise<R | null> =
  reports('getVehicleEquipment')

//Transports
const transportsCall = apiServiceBindModule('transports')
export const getTransports = transportsCall('getTransports')

//stat
const statCall = apiServiceBindModule('stat')
export const getDetailsByImei = statCall('getDetailsByImei')
export const getGeoZoneDetails = statCall('getGeoZoneDetails')
export const getModeIntervals = statCall('getModeIntervals')
export const getMultiNotices = statCall('getMultiNotices')
export const getSummary = statCall('getSummary')
export const getTimeUsage = statCall('getTimeUsage')
export const getTrack = statCall('getTrackStat')
export const getTargets2 = statCall('getTargets2')
export const getSensorsData = statCall('getSensorsData')
export const getGroupeMachines = statCall('getGroupeMachines')
export const getRegistersWithUnitsGrouping = statCall('getRegistersWithUnitsGrouping')

//calendar
const calendarCall = apiServiceBindModule('calendar')
export const getCalendarProperties = calendarCall('getProperties')
export const getCalendarValue = calendarCall('getValue')

//events
const eventsCall = apiServiceBindModule('events')
export const getTrackEventsByUnit = eventsCall('getTrackEventsByUnit')

//offlineReceiver
const offlineReceiver = apiServiceBindModule('offlineReceiver')
export const uploadFileToOfflineReceiver = offlineReceiver('upload')

//feedback
export const feedbackCall = apiServiceBindModule('feedback')
export const sendFeedback = feedbackCall('sendFeedback')

