import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import ContactPage from './containers/Contact'
import Home from './containers/Home'
import MachineDetail from './containers/MachineDetail'
import Machines from './containers/Machines'
import Tracking from './containers/Tracking'
import { Watch } from './containers/Watch'
import Import from './pages/Import'
import Notifications from './pages/Notification'
import { Profile } from './pages/Profile'

export const link = {
  adminpanel                : `${process.env.PUBLIC_URL}/manage/adminpanel`,
  catalogs                  : `${process.env.PUBLIC_URL}/catalogs`,
  contact                   : `${process.env.PUBLIC_URL}/contact`,
  detail                    : `${process.env.PUBLIC_URL}/machines/detail`,
  farms                     : `${process.env.PUBLIC_URL}/manage/farms`,
  geoZone                   : `${process.env.PUBLIC_URL}/tracking/geoZone`,
  home                      : `${process.env.PUBLIC_URL}/`,
  households                : `${process.env.PUBLIC_URL}/tracking/households`,
  import                    : `${process.env.PUBLIC_URL}/import`,
  machines                  : `${process.env.PUBLIC_URL}/machines`,
  map                       : `${process.env.PUBLIC_URL}/map`,
  notifications             : `${process.env.PUBLIC_URL}/notifications`,
  planning                  : `${process.env.PUBLIC_URL}/planning/operations`,
  profile                   : `${process.env.PUBLIC_URL}/profile`,
  resetpassword             : `${process.env.PUBLIC_URL}/resetpassword`,
  roles                     : `${process.env.PUBLIC_URL}/manage/roles`,
  tracking                  : `${process.env.PUBLIC_URL}/tracking`,
  users                     : `${process.env.PUBLIC_URL}/manage/users`,
  watch                     : `${process.env.PUBLIC_URL}/analysis`,
  watchAgroOperations       : `${process.env.PUBLIC_URL}/analysis/multiple/agroOperations`,
  watchCleaning             : `${process.env.PUBLIC_URL}/analysis/multiple/cleaning`,
  watchEquipment            : `${process.env.PUBLIC_URL}/analysis/multiple/equipment`,
  watchExport               : `${process.env.PUBLIC_URL}/analysis/export`,
  watchFueling              : `${process.env.PUBLIC_URL}/analysis/multiple/fueling`,
  watchFuelReport           : `${process.env.PUBLIC_URL}/analysis/multiple/fuelReport`,
  watchGroupCleaningReport  : `${process.env.PUBLIC_URL}/analysis/multiple/groupCleaningReport`,
  watchGroupMachineryReports: `${process.env.PUBLIC_URL}/analysis/multiple/groupMachineryReports`,
  watchMachineReports       : `${process.env.PUBLIC_URL}/analysis/single/machineReports`,
  watchMultiple             : `${process.env.PUBLIC_URL}/analysis/multiple`,
  watchOperators            : `${process.env.PUBLIC_URL}/analysis/multiple/operators`,
  watchPerformance          : `${process.env.PUBLIC_URL}/analysis/multiple/performance`,
  watchPerformanceIndicators: `${process.env.PUBLIC_URL}/analysis/single/performanceIndicators`,
  watchSingle               : `${process.env.PUBLIC_URL}/analysis/single`,
  watchTime                 : `${process.env.PUBLIC_URL}/analysis/multiple/time`,
  watchUnloading            : `${process.env.PUBLIC_URL}/analysis/multiple/unloading`,
} as const

export const RouterBlock = (): ReactElement =>
  <Switch>
    <Route exact path={link.home} component={Home} />
    <Route exact path={link.machines} component={Machines} />
    <Route path={link.detail} component={MachineDetail} />
    <Route path={link.notifications} component={Notifications} />
    <Route path={link.watch} component={Watch} />
    <Route path={link.tracking} component={Tracking} />
    <Route path={link.contact} component={ContactPage} />
    <Route path={link.profile} component={Profile} />
    <Route path={link.import} component={Import} />
  </Switch>

