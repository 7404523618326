import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-redux-multilingual'
import { Link, Redirect,Route, Switch } from 'react-router-dom'
import { AppStateType } from 'reducers/store'
import { link } from '../../router'
import TooltipD from '../../ui/Tooltip'
import AgroOperations from './AgroOperations'
import ReportCleaning from './Cleaning'
import ReportEquipment from './Equipment'
import Fueling from './Fueling'
import FuelReport from './FuelReport'
import GroupCleaningReport from './GroupCleaningReport'
import GroupMachineryReports from './GroupMachineryReports'
import ReportOperators from './Operators'
import ReportPerformance from './Performance'
import Time from './Time'
import ReportUnloading from './Unloading'

const styles = {
  tooltip: { bottom: '0px', right: '0px', zIndex: '1000' },
}

type WatchMultipleProps = {
  location: {
    pathname: string
    history?: {
      push: (value: string) => void
    }
  }
}

export const WatchMultiple: FC<WatchMultipleProps> = ({ location: { pathname, history } }) => {
  const translate = useTranslate()
  const fixedDataFilter = useSelector((state: AppStateType) => state.reports.get('fixedDataFilter'))

  useEffect(() =>{
    if(pathname === link.watchMultiple && fixedDataFilter) {
      history?.push(`${link.watchTime}${fixedDataFilter || ''}`)
    }
  }, [])

  return (
    <div>
      <TooltipD text={translate('Report Type Selection Menu')} style={styles.tooltip}/>
      <div className="watch__navigations">
        <Link to={`${link.watchTime}${fixedDataFilter || ''}`}
          className={pathname === link.watchTime || pathname === link.watchMultiple ? 'active' : undefined}
        >
          {translate('use_of_time')}
        </Link>

        <Link to={`${link.watchFueling}${fixedDataFilter || ''}`}
          className={pathname === link.watchFueling ? 'active' : undefined}
        >
          {translate('drain')} / {translate('refuel')}
        </Link>

        <Link to={`${link.watchFuelReport}${fixedDataFilter || ''}`}
          className={pathname === link.watchFuelReport ? 'active' : undefined}
        >
          {translate('fuel_report')}
        </Link>

        <Link
          to={`${link.watchGroupMachineryReports}${fixedDataFilter || ''}`}
          className={pathname === link.watchGroupMachineryReports ? 'active' : undefined}
        >
          {translate('consolidated_statement_about_machines')}
        </Link>

        <Link
          to={`${link.watchGroupCleaningReport}${fixedDataFilter || ''}`}
          className={pathname === link.watchGroupCleaningReport ? 'active' : undefined}
        >
          {'Отчет по групповой уборке'}
        </Link>

        <Link
          to={`${link.watchAgroOperations}${fixedDataFilter || ''}`}
          className={pathname === link.watchAgroOperations ? 'active' : undefined}
        >
          {translate('events_a_o')}
        </Link>

        <Link
          to={`${link.watchCleaning}${fixedDataFilter || ''}`}
          className={pathname === link.watchCleaning ? 'active' : undefined}
        >
          {translate('report cleaning')}
        </Link>

        <Link
          to={`${link.watchUnloading}${fixedDataFilter || ''}`}
          className={pathname === link.watchUnloading ? 'active' : undefined}
        >
          {translate('report unloading')}
        </Link>

        <Link
          to={`${link.watchEquipment}${fixedDataFilter || ''}`}
          className={pathname === link.watchEquipment ? 'active' : undefined}
        >
          {translate('report equipment')}
        </Link>

        <Link
          to={`${link.watchPerformance}${fixedDataFilter || ''}`}
          className={pathname === link.watchPerformance ? 'active' : undefined}
        >
          {translate('report performance')}
        </Link>

        <Link
          to={`${link.watchOperators}${fixedDataFilter || ''}`}
          className={pathname === link.watchOperators ? 'active' : undefined}
        >
          {translate('operators')}
        </Link>

      </div>
      <Switch>
        <Redirect exact from={link.watchMultiple} to={link.watchTime} />
        <Route path={link.watchTime} component={Time} />
        <Route path={link.watchFueling} component={Fueling} />
        <Route path={link.watchFuelReport} component={FuelReport} />
        <Route path={link.watchGroupCleaningReport} component={GroupCleaningReport} />
        <Route path={link.watchGroupMachineryReports} component={GroupMachineryReports} />
        <Route path={link.watchAgroOperations} component={AgroOperations} />
        <Route path={link.watchCleaning} component={ReportCleaning} />
        <Route path={link.watchUnloading} component={ReportUnloading} />
        <Route path={link.watchEquipment} component={ReportEquipment} />
        <Route path={link.watchPerformance} component={ReportPerformance} />
        <Route path={link.watchOperators} component={ReportOperators} />
      </Switch>
    </div>
  )
}
